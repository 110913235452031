<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ppmpModal"
    >
      <v-card>
        <v-toolbar dark color="green darken-2">
          <v-toolbar-title>Purchase Requests</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="clickCloseMainDialogPR()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col lg="6">
            <v-data-table
              :headers="headers"
              :search="searchText"
              :items="prList"
              class="elevation-0"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field
                    style="max-width: 300px"
                    v-model="searchText"
                    append-icon="mdi-magnify"
                    placeholder="Search.."
                    color="green darken-2"
                    class="mb-2 mr-2"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.PRID">
                    <td>
                      <v-checkbox
                        v-model="formData.Lists"
                        :value="item"
                        style="margin: 0px; padding: 0px"
                        hide-details
                      />
                    </td>
                    <td>{{ item.PRCODETemp }}</td>
                    <td style="max-width:200px;">{{ item.PrPurpose }}</td>
                    <td>{{ item.FundCode }}</td>
                    <td>{{ item.DateCreated }}</td>
                    <td>
                      <v-icon small class="mr-2" @click="viewPR(item)">
                        visibility
                      </v-icon>
                      <v-icon
                        small
                        class="mr-2"
                        color="red accent-4"
                        @click="print(item)"
                      >
                        mdi-printer
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-slot:no-data> No Records Yet </template>
            </v-data-table>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col lg="6">
            <v-data-table
              :headers="headers1"
              :search="searchText1"
              :items="formData.Lists"
              :loading="loading"
              class="elevation-0"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-text-field
                    style="max-width: 300px"
                    v-model="searchText1"
                    append-icon="mdi-magnify"
                    placeholder="Search.."
                    color="green darken-2"
                    class="mb-2 mr-2"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="red darken-2"
                    dark
                    outlined
                    elevation="2"
                    class="mb-2 mr-2"
                    @click="reset()"
                  >
                  <v-icon>mdi-refresh</v-icon>
                    Reset
                  </v-btn>
                  <v-btn
                    color="green darken-2"
                    outlined
                    :disabled="formData.Lists.length < 1"
                    elevation="2"
                    class="mb-2"
                    @click="consolidate()"
                  >
                    <v-icon>mdi-file-sync-outline</v-icon>
                    Consolidate
                  </v-btn>
                </v-toolbar>
              </template>
                <template v-slot:body="{ items }">
                <tbody>
                  <tr v-for="item in items" :key="item.PRID">
                    <td>{{ item.PRCODETemp }}</td>
                    <td style="max-width:200px;">{{ item.PrPurpose }}</td>
                    <td>{{ item.FundCode }}</td>
                    <td>{{ item.DateCreated }}</td>
                    <td>
                      <v-icon
                        small
                        class="mr-2"
                        color="red accent-4"
                        @click="removefromlist(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </tbody>
              </template>
              <template v-slot:no-data> No Records Yet </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <prModlal :prData="prItem" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  props: {
    prData: {},
    dialogMenu: null,
  },
  components: {
    prModlal: () => import("./PRViewItem"),
  },
  data: () => ({
    dialog: false,
    searchText: "",
    searchText1: "",
    searchTimeout: null,
    amountErr: null,
    editItemId: null,
    loading: true,
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    menu: false,
    menu1: false,
    formData: {
      Lists: []
    },
    addPPMPDialog: false,
    prList: [],
    budgetList: [],
    prItem: [],

    headers: [
      {
        text: "",
        sortable: true,
        value: "checkbox",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR No.",
        sortable: true,
        value: "PRCODETemp",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Purpose",
        value: "PrPurpose",
        sortable: true,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Fund",
        value: "FundCode",
        sortable: true,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Date",
        value: "DateCreated",
        sortable: true,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],

       headers1: [
      {
        text: "PR No.",
        sortable: true,
        value: "PRCODETemp",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Purpose",
        value: "PrPurpose",
        sortable: true,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Fund",
        value: "FundCode",
        sortable: true,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Date",
        value: "DateCreated",
        sortable: true,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
  }),
  watch: {
    prData: {
      handler(data) {
        if (data.PPMPID) {
          this.dialogAddItem = true;
          this.getItems();
        }
      },
      deep: true,
    },
    dialogMenu: {
      handler(data) {
        if (data) {
          this.dialog = true;
          this.initialize();
        }
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.eventHub.$on("closePRItemodal", () => {
      this.prItem = {};
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closePRItemodal");
  },
  methods: {
    clickCloseMainDialogPR() {
      this.formData.Lists = [];
      this.dialog = false;
      this.prList = [];
      this.eventHub.$emit("closeModalCons", false);
    },
    print(data) {
      window.open(this.apiUrl + "/pr/report/" + data.PRID);
    },
    viewPR(item) {
      this.prItem = item;
      console.log(this.prItem);
    },
    initialize() {
      this.loading = true;
      let data = new FormData();
      data.append("searchText", '');
      this.axiosCall("/get/pr/to/consolidate", "POST", data).then((res) => {
        this.loading = false;
        this.prList = res.data.data.result;
      });
    },
    consolidate() {
      this.loading = true;
      let data = new FormData();
      data.append("List", JSON.stringify(this.formData.Lists));
      this.axiosCall("/consolidate/dbm/pr", "POST", data).then((res) => {
        console.log(res);
        this.loading = false;
        this.clickCloseMainDialogPR();
      });
    },
    reset () {
      this.formData.Lists = [];
    },
    removefromlist (data) {

      for(let [index, item] of  Object.entries(this.formData.Lists)){
					if(data.PRID == item.PRID){
            this.formData.Lists.splice(index, 1); 
					}
				}
    }
  },
};
</script>